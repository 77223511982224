<template>
  <div id="choose-receipt-container" :class="{ 'smaller-margins': cartSummaryInPdaDesktop }">
    <div id="choose-receipt-internal-container">
      <h5 id="receipt-title">
        <font-awesome-icon
          icon="cash-register"
          style="margin-right: 4px; margin-bottom:1px; color: #006adb"
        />Να βγει και απόδειξη;
      </h5>
      <div id="receipt-options">
        <div class="receipt-option" style="margin-right: 20px;">
          <input
            class="input"
            type="radio"
            :value="false"
            v-model="willPublishReceipt"
            @change="onselectPublishReceipt"
          />
          <h6
            @click="selectPublishReceipt(false)"
            :class="{ selectedBolder: !willPublishReceipt }"
          >Όχι</h6>
        </div>
        <div class="receipt-option">
          <input
            class="input"
            type="radio"
            :value="true"
            v-model="willPublishReceipt"
            @change="onselectPublishReceipt"
          />
          <h6 @click="selectPublishReceipt(true)" :class="{ selectedBolder: willPublishReceipt}">Ναι</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ReceiptOrNot",

  props: ["cartSummaryInPdaDesktop"],
  components: {

  },
  data() {
    return {
      willPublishReceipt: false
    }
  },
  watch: {

  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

  },
  methods: {
    selectPublishReceipt(willPublishReceipt) {
      this.willPublishReceipt = willPublishReceipt;
      this.$emit("change-publish-receipt-selection", this.willPublishReceipt)
    },
    onselectPublishReceipt() {
      this.$emit("change-publish-receipt-selection", this.willPublishReceipt)
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
#choose-receipt-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

.smaller-margins {
  margin-bottom: 0px !important;
  padding: 2px 0 1px 0 !important;
}

.selectedBolder {
  color: black;
  font-weight: 600;
}

#receipt-title {
  font-weight: 600;
  font-size: 14px;
}

#choose-receipt-internal-container {
  display: flex;
  flex-direction: column;
}

#receipt-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.receipt-option {
  display: flex;
  flex-direction: row;
  color: #6d6d6d;
  cursor: pointer;
}

.receipt-option > h6 {
  font-size: 14px;
}

.input {
  accent-color: #198b4a;
  margin-bottom: 6px;
  margin-right: 5px;
}
</style>